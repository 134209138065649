const swiperOurMission = new Swiper(".swiper-our-mission", {
  direction: "horizontal",
  slidesPerView: 1,

  breakpoints: {
    768: {
      slidesPerView: 1,
      spaceBetween: 10,
    },

    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  },

  pagination: {
    el: ".swiper-pagination-our-mission",
    type: "bullets",
    clickable: true,
  },
});

const swiperReviews = new Swiper(".reviews-swiper", {
  direction: "horizontal",
  slidesPerView: 1,
  loop: true,
  centeredSlides: true,

  breakpoints: {
    960: {
      slidesPerView: 2,
      spaceBetween: 55,
    },

    320: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
  },

  pagination: {
    el: ".swiper-pagination-reviews-swiper",
    type: "bullets",
    clickable: true,
  },
});

const form = document.querySelector("#form");
const button = document.querySelector("#submit-button");

const onClear = () => {
  form.querySelector("#name").value = "";
  form.querySelector("#phone").value = "";
  form.querySelector("#message").value = "";
};

const onSubmit = async (e) => {
  e.preventDefault();

  const data = new FormData(e.target);

  try {
    await fetch("/server/mail.php", {
      method: "POST",
      body: data,
    });
  } catch (e) {
  } finally {
    Swal.fire("Thank you!", "Application successfully sent!", "success");
    onClear();
  }
};

form.addEventListener("submit", onSubmit);

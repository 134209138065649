const swiperOurWorks = new Swiper(".swiper-our-works", {
  direction: "horizontal",
  slidesPerView: 1,

  navigation: {
    nextEl: ".swiper-our-works .swiper-button-next-our-works",
    prevEl: ".swiper-our-works .swiper-button-prev-our-works ",
  },

  pagination: {
    el: ".swiper-pagination-our-works",
    type: "bullets",
    clickable: true,
  },

  breakpoints: {
    768: {
      slidesPerView: 1,
      spaceBetween: 45,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 45,
    },
  },
});

const swiper = new Swiper(".gallery-bottom", {
  spaceBetween: 10,
  slidesPerView: 5,
  watchSlidesProgress: true,
});

const swiper2 = new Swiper(".gallery-top", {
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: swiper,
  },
});

const lightbox = new PhotoSwipeLightbox({
  gallery: ".gallery-top",
  children: "a",
  pswpModule: PhotoSwipe,
  loop: false,

  padding: { top: 20, bottom: 40, left: 100, right: 100 },
});

lightbox.init();

const btns = document.querySelectorAll(".gallery-top .zoom");
btns.forEach((btn, i) => {
  btn.addEventListener("click", () => {
    lightbox.loadAndOpen(i, {
      gallery: document.querySelector(".gallery-top"),
    });
  });
});

const texts = document.querySelectorAll(".info-block");
swiper2.on("slideChange", function (e) {
  const index = e.activeIndex;
  console.log(index);
  texts.forEach((text) => {
    text.classList.remove("visible");
  });

  texts[index].classList.add("visible");
});

const $body = document.body;

const scrollLock = {
  enable() {
    scrollPosition = window.pageYOffset;
    $body.style.overflow = "hidden";
    $body.style.position = "fixed";
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = "100%";
  },
  disable() {
    $body.style.removeProperty("overflow");
    $body.style.removeProperty("position");
    $body.style.removeProperty("top");
    $body.style.removeProperty("width");
  },
};

const burgerMenu = document.querySelector(".mobile-menu");
const burgers = document.querySelectorAll(".burger");

burgers.forEach((burger) => {
  burger.addEventListener("click", (e) => {
    e.preventDefault();

    if (burger.classList.contains("is-active")) {
      burgerMenu.classList.remove("open");
      scrollLock.disable();
    } else {
      burgerMenu.classList.add("open");
      scrollLock.enable();
    }
  });
});

let scrollPosition = 0;

const links = document.querySelectorAll("a[data-inner-navigation]");

links.forEach((link) => {
  link.addEventListener("click", (e) => {
    e.preventDefault();
    scrollLock.disable();

    burgerMenu.classList.remove("open");

    const target = document.querySelector(link.getAttribute("href"));

    target.scrollIntoView({
      behavior: "smooth",
    });
  });
});
